<template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="sm"
        :click-to-close="false"
        @hide="$emit('hide')"
    >
        <div slot="header">
            <h2 class="font-normal">{{ id ? 'Edit' : 'Add' }} agreement</h2>
        </div>

        <div slot="body">
            <div v-if="loading" class="flex flex__justify-center w__100-p">
                <spinner />
            </div>

            <div v-else>
                <modal-field label="Organization" class="mb-3" required>
                    <multiselect
                        v-model="organization_ids"
                        :options="organizationsList"
                        multiple
                        track-by="id"
                        label="name"
                        :class="{'is-invalid': submitter && $v.organization_ids.$invalid}"
                    />
                </modal-field>

                <modal-field label="Carrier service" class="mb-3" required>
                    <multiselect
                        v-model="services"
                        group-label="name"
                        :group-select="false"
                        group-values="services"
                        :options="carrierServiceList"
                        label="name"
                        track-by="id"
                        multiple
                        :class="{'is-invalid': submitter && $v.services.$invalid}"
                    >
                    </multiselect>
                </modal-field>

                <modal-field label="Payer" class="mb-3" required>
                    <multiselect
                        v-model="payers"
                        :options="payerList"
                        track-by="value"
                        label="label"
                        multiple
                        :class="{'is-invalid': submitter && $v.payers.$invalid}"
                    />
                </modal-field>

                <modal-field label="Consignor name" class="mb-3">
                    <autocomplete
                        v-model="consignor_name"
                        :class="{'is-invalid': submitter && $v.consignor_name.$invalid}"
                        :endpoint="
                            filterOptionsUrl({
                                role: 'consignor_name'.split('_')[0],
                                field: 'consignor_name'.split('_')[1],
                            })
                        "
                        @click="updateField('consignor_name', $event)"
                    />
                </modal-field>

                <modal-field label="Consignee name" class="mb-3">
                    <autocomplete
                        v-model="consignee_name"
                        :class="{'is-invalid': submitter && $v.consignee_name.$invalid}"
                        :endpoint="
                            filterOptionsUrl({
                                role: 'consignee_name'.split('_')[0],
                                field: 'consignee_name'.split('_')[1],
                            })
                        "
                        @click="updateField('consignee_name', $event)"
                    />
                </modal-field>

                <modal-field label="Delivery country" class="mb-3">
                    <input type="text" v-model="delivery_country_code">
                </modal-field>

                <modal-field label="Pickup country" class="mb-3">
                    <input type="text" v-model="pickup_country_code">
                </modal-field>

                <modal-field label="Pickup city" class="mb-3">
                    <autocomplete
                        v-model="pickup_city"
                        :class="{'is-invalid': submitter && $v.pickup_city.$invalid}"
                        :endpoint="
                            filterOptionsUrl({
                                role: 'pickup_city'.split('_')[0],
                                field: 'pickup_city'.split('_')[1],
                            })
                        "
                        @click="updateField('pickup_city', $event)"
                    />
                </modal-field>

                <modal-field label="Consignor agreement no" class="mb-3">
                    <input v-model="consignor_agreement_no" type="text" />
                </modal-field>

                <modal-field label="Consignor agreement name" class="mb-3">
                    <input v-model="consignor_agreement_name" type="text" />
                </modal-field>

                <modal-field label="Consignee agreement no" class="mb-3">
                    <input v-model="consignee_agreement_no" type="text" />
                </modal-field>

                <modal-field label="Consignee agreement name" class="mb-3">
                    <input v-model="consignee_agreement_name" type="text" />
                </modal-field>

                <label class="flex items-center cursor-pointer">
                    <input
                        v-model="is_daily_pickup"
                        type="checkbox"
                        class="form-checkbox"
                    />
                    <span class="ml-2 text-sm text-gray-700">Is daily pickup</span>
                </label>
            </div>
        </div>

        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full">
                <div class="flex">
                    <button class="px-10 btn-primary" :disabled="btnLoading || $v.$invalid || btnDisabled" @click="submit">{{ id ? 'Save' : 'Add' }}</button>

                    <button class="px-8 ml-4 btn-grey-outline" @click="$emit('hide')">Cancel</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import { requestErrors } from '~/utils/errors';

import Spinner from '~/components/Spinner.vue';
import Autocomplete from '~/components/Autocomplete.vue';

export default {
    name: 'AgreementsModal',

    props: {
        id: {
            type: Number,
            default: null,
        },
        organizationId: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            loading: true,
            submitter: false,
            btnLoading: false,

            organization_ids: null,
            carriers: null,
            services: null,
            payers: null,
            consignor_name: '*',
            consignee_name:'*',
            pickup_city: '*',
            pickup_country_code: '*',
            delivery_country_code: '*',
            consignor_agreement_no: null,
            consignor_agreement_name: null,
            consignee_agreement_no: null,
            consignee_agreement_name: null,
            is_daily_pickup: 0,

            carrierServiceList: [],
            organizationsList: [],
            payerList: [
                { label: 'Consignor', value: 'consignor' },
                { label: 'Consignee', value: 'consignee' },
                { label: 'Third party', value: 'third party' },
            ],
        }
    },

    computed: {
        sendData() {
            return {
                organization_ids: this.organization_ids.map(organization => organization.id),
                carrier_service_ids: this.services.map(service => service.id),
                payers: this.payers.map(payer => payer.value),
                consignor_name: this.consignor_name || '*',
                consignee_name: this.consignee_name  || '*',
                pickup_city: this.pickup_city  || '*',
                pickup_country_code: this.pickup_country_code || '*',
                delivery_country_code: this.delivery_country_code || '*',
                consignor_agreement_no: this.consignor_agreement_no,
                consignor_agreement_name: this.consignor_agreement_name,
                consignee_agreement_no: this.consignee_agreement_no,
                consignee_agreement_name: this.consignee_agreement_name,
                is_daily_pickup: this.is_daily_pickup ? 1 : 0,
            }
        },
        btnDisabled() {
            if (this.consignor_agreement_no && this.consignor_agreement_name) {
              return false
            }

            if (this.consignee_agreement_no && this.consignee_agreement_name) {
              return false
            }

            return true
        }
    },

    methods: {
        updateField(fieldName, value) {
            this[fieldName] = value;
        },
        filterOptionsUrl({role, field}) {
            if (!this.organization_id?.id) {
                return null;
            }

            return (value) => {
                return this.$apiUrl.addressBook.filterOptions
                    + `?filter[organization_id]=${this.organization_id.id}`
                    + `&field=${field}`
                    + `&role=${role}`
                    + `&value=${value}`;
            };
        },
        async fetchOrganizations() {
            const { data } = await axios.get(`${this.$apiUrl.organizations.base}?pageSize=1000`);

            this.organizationsList = data.data;
            this.organization_id = this.organizationsList.find(org => org.id === this.organizationId);
        },

        async fetchCarrierList() {
            const { data: { data: { carrier_data: { filtered_carriers }} } } = await axios.get('/api/filter-options?filter[date_between]=today')

            this.carrierServiceList = filtered_carriers.map(item => {
                return {
                    id: item.carrier.id,
                    name: item.carrier.name,
                    services: item.services,
                }
            });
        },

        async setup() {
            if (!this.id) {
                return;
            }

            const { data: { data } } = await axios.get(`/api/unifaun-agreement/${this.id}`);

            this.consignor_agreement_no = data.consignor_agreement_no;
            this.consignee_agreement_no = data.consignee_agreement_no;
            this.pickup_city = data.pickup_city;
            this.pickup_country_code = data.pickup_country_code;
            this.delivery_country_code = data.delivery_country_code;
            this.payer = this.payerList.find(item => data.payer === item.value);
            this.is_daily_pickup = data.is_daily_pickup;
            this.consignor_name = data.consignor_name;
            this.consignee_name = data.consignee_name;
            this.consignor_agreement_name = data.consignor_agreement_name;
            this.organization_id = this.organizationsList.find(item => data.organization_id === item.id);

            const allServices = this.carrierServiceList.map(item => item.services).flat();

            this.service = allServices.find(item => item.id === data.carrier_service_id );
        },

        async submit() {
            this.submitter = true;

            if (this.$v.$invalid) {
                return;
            }

            this.btnLoading = true;

            try {
                await axios.post(this.$apiUrl.booking.batch,
                    {
                        ...this.sendData,
                        priority: this.organizationsList.length + 1,
                    },
                );

                this.$snotify.success(`Successfully`);

                this.$emit('save');
                this.$emit('hide')
            } catch(error) {
                this.$snotify.error(requestErrors(error));
            } finally {
                this.btnLoading = false;
            }
        },
    },

    async created() {
        await this.fetchOrganizations();
        await this.fetchCarrierList();

        await this.setup();

        this.loading = false;
    },

    components: {
        Spinner,
        Autocomplete,
    },

    validations: {
        organization_ids: {
            required,
        },
        services: {
            required,
        },
        payers: {
            required,
        },
    }
}
</script>
